import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Row, Button, Col, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import { format } from 'date-fns';
import History_view_modal from "./modal/history_view_modal";
import Csv_history_stok from "./csv_history_stok";
import Paginator from "react-hooks-paginator";
import debounce from 'lodash.debounce';


const HistoryStok = () => {
    const URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [showCsv, setShowCsv] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchFilters, setSearchFilters] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);

    const data = {
        columns: [
            {
                label: "No",
                field: "no",
                sort: "asc",
                width: 150,
            },
            // {
            //     label: "User",
            //     field: "user",
            //     sort: "asc",
            //     width: 270,
            // },
            {
                label: "Action",
                field: "description",
                sort: "asc",
                width: 200,
            },
            {
                label: "Nama",
                field: "name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Warna",
                field: "color",
                sort: "asc",
                width: 100,
            },
            {
                label: "Barcode",
                field: "barcode",
                sort: "asc",
                width: 150,
            },
            {
                label: "Stok",
                field: "stok",
                sort: "asc",
                width: 100,
            },
            {
                label: "Tanggal",
                field: "createdAt",
                sort: "asc",
                width: 100,
            },
            {
                label: "View",
                field: "view",
                sort: "asc",
                width: 100,
            },
        ],
        rows: history.map((item, index, historyArray) => {

            return {
                no: index + 1,
                description: item.description,
                name: item.name,
                color: item.color,
                barcode: item.barcode,
                stok: item.stok,
                createdAt: format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm'),

                view: (
                    <Button size='sm' variant="info" onClick={() => showmodal(item.id)}>
                        View
                    </Button>
                )
            }
        })

    };


    const showmodal = (id) => {
        setSelectedProductId(id);
        setShow(true);
    }

    // const handleInfoClick = (id) => {
    //     setSelectedProductId(id);
    //     setShowCsv(true);
    // };

    const successMessage = (success_message) => {
        toast.success(success_message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };
    const errorMessage = (error_Message) => {
        toast.error(error_Message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const fetchAccessToken = async () => {
        try {
            const response = await axios.post(`${URL}/api/v1/token/`);
            return response.data.accessToken;
        } catch (error) {
            setError("Failed to fetch access token. Redirecting...");
            navigate("/login");
        }
    };

    const fetchHistory = async () => {
        const URL = process.env.REACT_APP_API_URL;
        const token = await fetchAccessToken();
        try {
            const response = await axios.get(`${URL}/api/v1/history/stok`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    filter: searchFilters || undefined,
                    sort: sortOrder || undefined,
                    sortBy: sortBy || undefined,
                    page: currentPage,
                    paginate: pageSize,
                },
            });
            const { docs, total } = response.data.data;
            setHistory(docs);
            setTotal(total);
            setLoading(false);
        } catch (error) {
            setError("Failed to fetch history data. Redirecting...");
        }
    };


    useEffect(() => {
        fetchHistory();
    }, [currentPage, pageSize, searchFilters, sortOrder, sortBy]);

    useEffect(() => {
        if (loading) {
            loadingbar();
        }
    }, [loading])

    function loadingbar() {
        return (
            <div className="d-flex justify-content-center align-items-center" style={styles.container}>
                <Spinner animation="border" />
            </div>)

    }

    const styles = {
        container: {
            height: '100vh',
        },
    };

    const debouncedSearch = debounce((filters) => {
        setSearchFilters(filters);
        setCurrentPage(1);
    }, 300);

    const handleSearch = (filters) => {
        debouncedSearch(filters);
    };

    const handleSort = (field) => {
        if (field.column && field.column !== 'no' && field.column !== 'view') {
            setSortOrder(field.column);
            setSortBy(field.direction);
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(Number(event.target.value));
    };

    const getDisplayRange = () => {
        const start = (currentPage - 1) * pageSize + 1;
        const end = Math.min(start + pageSize - 1, total);
        return `Showing ${start} to ${end} of ${total} entries`;
    };

    const handleSearchInputChange = (event) => {
        const filters = event.target.value;
        handleSearch(filters);
    };

    return (
        <>

            <Row className="container mt-5">
                <ToastContainer />
                {loading ? (
                    loadingbar()
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <>

                        <div className="mb-3 d-flex justify-content-between align-items-center">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                onChange={handleSearchInputChange}
                            />
                            <Button size='sm' variant="info" onClick={() => setShowCsv(true)}>
                                Csv
                            </Button>
                        </div>
                        <div>
                            <MDBDataTable
                                striped
                                bordered
                                small
                                responsive
                                data={data}
                                searching={false}
                                onSort={handleSort}
                                paging={false}
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="form-group">
                                <label htmlFor="pageSizeSelect" className="mr-2">Items per page:</label>
                                <select
                                    id="pageSizeSelect"
                                    className="form-control d-inline-block w-auto"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <Paginator
                                totalRecords={total}
                                pageLimit={pageSize}
                                pageNeighbours={1}
                                currentPage={currentPage}
                                setCurrentPage={(page) => setCurrentPage(page)}
                                setOffset={(offset) => setOffset(offset)}
                            />
                            <div>
                                <span>{getDisplayRange()}</span>
                            </div>
                        </div>
                    </>
                )}
            </Row>

            <History_view_modal show={show} setShow={setShow} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} fetchAccessToken={fetchAccessToken} successMessage={successMessage} errorMessage={errorMessage} />
            <Csv_history_stok fetchAccessToken={fetchAccessToken} showCsv={showCsv} setShowCsv={setShowCsv} successMessage={successMessage} errorMessage={errorMessage} />
        </>
    )
}

export default HistoryStok
