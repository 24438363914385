import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Row, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import Add_user from './user_components/add_user';
import Edit_user from './user_components/edit_user';
import Delete_user from './user_components/delete_user';

const User = () => {
    const [user, setUser] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const navigate = useNavigate();
    const URL = process.env.REACT_APP_API_URL;

    const data = {
        columns: [
            {
                label: 'No',
                field: 'no',
                sort: 'asc',
                width: 150
            },
            {
                label: 'ID',
                field: 'id',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Username',
                field: 'username',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Role',
                field: 'role',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Action',
                field: 'action',
                sort: 'asc',
                width: 100
            }
        ],
        rows: user.map((userItem, index) => ({
            no: index + 1,
            id: userItem.id,
            username: userItem.username,
            role: userItem.roles,
            action: (
                <DropdownButton size='sm' variant="info" id="dropdown-basic-button" title="Dropdown button">
                    <Dropdown.Item onClick={() => handleInfoClick(userItem.id, "edit")}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleInfoClick(userItem.id, "delete")} >Hapus</Dropdown.Item>
                </DropdownButton>
            )
        }))
    };

    const handleInfoClick = (id, type) => {
        setSelectedUserId(id);
        if (type === "edit") {
            setShowEdit(true);
        } else if (type === "delete") {
            setShowDelete(true);
        } else if (type === "add") {
            setShowAdd(true);
        }
    };

    const fetchAccessToken = async () => {
        try {
            const response = await axios.post(`${URL}/api/v1/token/`);
            return response.data.accessToken;
        } catch (error) {
            setError("Failed to fetch access token. Redirecting...");
            navigate("/login");
        }
    };

    const fetchUser = async () => {
        const URL = process.env.REACT_APP_API_URL;
        const token = await fetchAccessToken();
        try {
            const response = await axios.get(`${URL}/api/v1/user/getall`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data.data;
            setUser(data);
            setLoading(false);
        } catch (error) {
            setError("Failed to fetch user data. Redirecting...");
        }
    };

    const successMessage = (success_message) => {
        toast.success(success_message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const errorMessage = (error_Message) => {
        toast.error(error_Message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    useEffect(() => {
        fetchUser();
    }, [refresh]);




    return (
        <Row className="container mt-5">
            <ToastContainer />
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div>
                    <Button  size='sm' variant="info" onClick={() => handleInfoClick(null, "add")}>Tambah</Button>
                    <MDBDataTable
                        striped
                        bordered
                        small
                        responsive
                        data={data}
                    />

                    <Add_user showAdd={showAdd} setShowAdd={setShowAdd} successMessage={successMessage} errorMessage={errorMessage} fetchAccessToken={fetchAccessToken} setRefresh={setRefresh} refresh={refresh}/>
                    <Edit_user showEdit={showEdit} setShowEdit={setShowEdit} successMessage={successMessage} errorMessage={errorMessage} fetchAccessToken={fetchAccessToken} user={user} selectedUserId={selectedUserId} setRefresh={setRefresh} refresh={refresh}/>
                    <Delete_user showDelete={showDelete} setShowDelete={setShowDelete} successMessage={successMessage} errorMessage={errorMessage} fetchAccessToken={fetchAccessToken} selectedUserId={selectedUserId} user={user} setRefresh={setRefresh} refresh={refresh}/>
                </div>
            )}
        </Row>
    )
}

export default User
